import "./styles.css";

import React, { useContext } from "react";

import Button from "components/common/Button";
import ButtonList from "components/common/ButtonList";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";

const SingleChoice = ({ onAnswerClick, onBackClick, answers }) => {
  const iln = useContext(TranslationContext);

  return (
    <div className="Single-choice-question">
      <ButtonList>
        {answers.map(
          ({ text, answerID, revealQuestion: nextQuestion }, index) => (
            <Button
              key={index}
              onClick={() =>
                onAnswerClick({
                  nextQuestion,
                  answerID
                })
              }
            >
              {text}
            </Button>
          )
        )}
      </ButtonList>
      {onBackClick && (
        <Button className="Back-button" onClick={onBackClick}>
          {iln.gettext("Back")}
        </Button>
      )}
    </div>
  );
};

SingleChoice.propTypes = propTypes;

export default SingleChoice;
