import "./styles.css";

import ErrorText from "components/common/ErrorText";
import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const Input = ({ name, className, errorText, keepErrorSpacing, ...props }) => {
  return (
    <React.Fragment>
      <input
        name={name}
        role="input"
        className={classnames(
          "Input",
          errorText && "Input-has-error",
          className?.input
        )}
        {...props}
      />
      {(errorText || keepErrorSpacing) && (
        <ErrorText className={className?.error}>{errorText}</ErrorText>
      )}
    </React.Fragment>
  );
};

Input.propTypes = propTypes;

export default Input;
