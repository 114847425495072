import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const List = ({ label, items, className, ...props }) => {
  return (
    <div className={classnames("List", className)} {...props}>
      {label && <div className="Label">{label}</div>}
      <ul className="Items">
        {items.map((value, idx) => (
          <li key={idx} className="Item">
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

List.propTypes = propTypes;

export default List;
