import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const Placeholder = ({ children, width, height, className, ...props }) => {
  return (
    <div
      {...props}
      style={{ width, height }}
      className={classnames("Placeholder", className)}
    >
      {children}
    </div>
  );
};

Placeholder.propTypes = propTypes;

export default Placeholder;
