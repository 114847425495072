import "./styles.css";

import React, { useContext } from "react";
import { getDBdateFormat, getDateForDisplay } from "helpers/dates";
import {
  getSurvey,
  getVoter,
  setVoter,
  getSelectedStreet,
  getSelectedTown
} from "helpers/localStorage";

import Button from "components/common/Button";
import List from "components/common/List";
import { ModalContext } from "context/Modal";
import NewVI from "./newVI";
import Page from "components/app/Page";
import Survey from "./Survey";
import { TranslationContext } from "context/Translation";
import api from "../../api";
import { useNavigate } from "react-router-dom";

const VoterPage = () => {
  const { modalActions } = useContext(ModalContext);
  const iln = useContext(TranslationContext);
  const { id, firstname, surname, votingIntentions } = getVoter();
  const survey = getSurvey().surveyContent;
  const navigate = useNavigate();
  const streetName = getSelectedStreet();
  const town = getSelectedTown();

  const getVI = ({ timestamp, flag }) =>
    `${getDateForDisplay(timestamp)} - ${flag}`;

  const surveyDetails = {
    statement: survey["intro"].text
  };

  const updateVI = (newIntention) => {
    votingIntentions.unshift({
      flag: newIntention,
      timestamp: getDBdateFormat()
    });
    setVoter({ ...getVoter(), votingIntentions: votingIntentions });
  };
  const handleNewIntention = () => {
    modalActions.open({
      title: "Record New Voting Intention",
      component: <NewVI constituentID={id} updateVI={updateVI} />
    });
  };

  const handleOpenSurveyModal = () => {
    modalActions.open({
      // title: "Record Survey Response",
      component: <Survey survey={survey} />
    });
  };

  const handleVoterDidNotParticipate = () => {
    api.didNotParticipateConstituent(id, getSurvey().id).then(() => {
      navigate(`/street/${town}/${streetName}`);
    });
  };

  return (
    <Page header={firstname + " " + surname}>
      <br></br>
      <div className="viHistory">
        <List
          label="VI History"
          items={votingIntentions.map((entry) => getVI(entry))}
        />
      </div>
      <div id="newIntention">
        <div id="newVIBtn">
          <Button onClick={handleNewIntention}>
            {iln.gettext("Record New Voting Intention")}
          </Button>
        </div>
      </div>
      <h1 id="surveyHeading">{iln.gettext("Survey")}</h1>
      <p>{surveyDetails.statement}</p>
      <div className="acceptBlock">
        <Button onClick={() => handleOpenSurveyModal()}>
          {iln.gettext("Accepted Survey")}
        </Button>
        <Button onClick={() => handleVoterDidNotParticipate()}>
          {iln.gettext("Rejected Survey")}
        </Button>
      </div>
    </Page>
  );
};

export default VoterPage;
