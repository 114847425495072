import { getToken, setToken } from "helpers/localStorage";

import axios from "axios";

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8899/api"
      : "https://doorknock-ca.caseworkermp.com/api",
  headers: {
    "Content-Type": "application/json"
  }
});

axiosInstance.interceptors.response.use((response) => {
  if (response.headers.authorization) {
    setToken(response.headers.authorization);
  }
  return response;
});

axiosInstance.interceptors.request.use((request) => {
  request.headers.Authorization = getToken() || "";
  return request;
});

export default axiosInstance;
