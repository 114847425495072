import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
  disableShimmer: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default propTypes;
