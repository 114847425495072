import "./styles.css";

import React, { useContext, useState } from "react";

import Button from "components/common/Button";
import Input from "components/common/Input";
import { TranslationContext } from "context/Translation";
import { isEmail } from "helpers/isEmail";
import mobileNumberToLocal from "./helpers/mobileNumberToLocal";
import phoneNumberToInternational from "./helpers/phoneNumberToInternational";
import propTypes from "./propTypes";
import telephoneNumberToLocal from "./helpers/telephoneNumberToLocal";

const validPhoneValuesOnly = (value) =>
  value.replace(new RegExp("[^0-9+() -]"), "");

const isGreaterThan10Digits = (value) => value.replace(/\D+/g, "").length > 10;

const ContactDetailCollection = ({
  onConfirmClick,
  contactDetails,
  onBackClick
}) => {
  const iln = useContext(TranslationContext);

  const [email, setEmail] = useState(contactDetails?.email || "");
  const [emailError, setEmailError] = useState("");
  const [telephone, setTelephone] = useState(
    telephoneNumberToLocal(contactDetails?.telephone || "")
  );
  const [telephoneError, setTelephoneError] = useState("");
  const [mobile, setMobile] = useState(
    mobileNumberToLocal(contactDetails?.mobile || "")
  );
  const [mobileError, setMobileError] = useState("");

  return (
    <React.Fragment>
      <div className="Label">{iln.gettext("Email")}</div>
      <Input
        name="email"
        value={email}
        errorText={emailError}
        onChange={({ target: { value } }) => {
          setEmail(value);
          value !== "" && !isEmail(value)
            ? setEmailError("Please enter valid email address")
            : setEmailError("");
        }}
        keepErrorSpacing
        className={{ error: "Email-collection-error" }}
      />
      <div className="Label">{iln.gettext("Telephone")}</div>
      <Input
        name="telephone"
        value={telephone}
        errorText={telephoneError}
        onChange={({ target: { value } }) => {
          setTelephone(validPhoneValuesOnly(value));
          isGreaterThan10Digits(value)
            ? setTelephoneError(
                "Telephone numbers cannot be longer than 10 digits"
              )
            : setTelephoneError("");
        }}
        keepErrorSpacing
        className={{ error: "Email-collection-error" }}
      />
      <div className="Label">{iln.gettext("Mobile")}</div>
      <Input
        name="mobile"
        value={mobile}
        errorText={mobileError}
        onChange={({ target: { value } }) => {
          setMobile(validPhoneValuesOnly(value));
          isGreaterThan10Digits(value)
            ? setMobileError("Mobile numbers cannot be longer than 10 digits")
            : setMobileError("");
        }}
        keepErrorSpacing
        className={{ error: "Email-collection-error" }}
      />

      <div className="Buttons-wrapper">
        {onBackClick && (
          <Button onClick={onBackClick}>{iln.gettext("Back")}</Button>
        )}
        <Button
          disabled={
            emailError !== "" || telephoneError !== "" || mobileError !== ""
          }
          onClick={() =>
            onConfirmClick({
              email,
              telephone: phoneNumberToInternational(telephone),
              mobile: phoneNumberToInternational(mobile)
            })
          }
        >
          {iln.gettext("Confirm")}
        </Button>
      </div>
    </React.Fragment>
  );
};

ContactDetailCollection.propTypes = propTypes;

export default ContactDetailCollection;
