import {
  getHadLocationPermissionBefore,
  setHadLocationPermissionBefore
} from "helpers/localStorage";
import {
  positionDistanceInterval,
  positionTimeoutInterval
} from "consts/intervals";
import { useEffect, useState } from "react";

import distanceBetweenLatLngs from "helpers/distanceBetweenLatLngs";
import { useNavigate } from "react-router-dom";

let goelocationID;
let intervalID;
let livePosition;

const requestLocationPermission =
  (setHasPermission) => (onSuccess, onError) => {
    // if: has geolocation support
    if (navigator.geolocation) {
      // Request location to trigger permission request
      navigator.geolocation.getCurrentPosition(
        // onSuccess
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setHasPermission(true);
          onSuccess({ lat, lng });
          setHadLocationPermissionBefore(true);
        },
        // onFailure
        (error) => {
          onError(error);
          setHadLocationPermissionBefore(false);
        }
      );
    } else {
      onError({ code: -1 });
      setHadLocationPermissionBefore(false);
    }
  };

const updatePosition = (setPosition) =>
  setPosition((statePosition) =>
    !statePosition ||
    distanceBetweenLatLngs(
      statePosition?.lat,
      statePosition?.lng,
      livePosition?.lat,
      livePosition?.lng
    ) > positionDistanceInterval
      ? livePosition
      : statePosition
  );

const useWatchPosition = () => {
  const navigate = useNavigate();
  const [position, setPosition] = useState();
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (hasPermission || getHadLocationPermissionBefore()) {
      goelocationID = navigator.geolocation.watchPosition(
        // onSuccess
        ({ coords: { latitude, longitude } }) => {
          const coords = {
            lat: Math.round(latitude * 1000000) / 1000000,
            lng: Math.round(longitude * 1000000) / 1000000
          };
          !livePosition && setPosition(coords);
          livePosition = coords;
        },
        // onFailure
        () => {
          setHadLocationPermissionBefore(false);
          setHasPermission(false);
          navigate("/permissions");
        },
        // options
        { enableHighAccuracy: true }
      );

      intervalID = setInterval(() => {
        updatePosition(setPosition);
      }, positionTimeoutInterval);
    }

    return () => {
      navigator.geolocation.clearWatch(goelocationID);
      clearInterval(intervalID);
    };
  }, [hasPermission]);

  return {
    position,
    hasPermission,
    requestLocationPermission: requestLocationPermission(setHasPermission)
  };
};

export default useWatchPosition;
