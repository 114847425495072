import "./style.css";
import React from "react";
import Spinner from "components/common/Spinner";

const ButtonSpinner = () => {
  return (
    <div className="Spinner-container">
      <Spinner size={"medium"} />
    </div>
  );
};

export default ButtonSpinner;
