import ContactDetailCollection from "./ContactDetailCollection";
import MultipleChoice from "./MultipleChoice";
import React from "react";
import SingleChoice from "./SingleChoice";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";
import shuffleArray from "./helpers/shuffleArray";
import { useContext } from "react";

const isTrue = (value) => value === "true" || value === true;

const getQuestion = ({
  questionType,
  next,
  back,
  questionNumber,
  answers,
  contactDetails,
  randomise,
  pop,
  iln
}) => {
  if (isTrue(randomise)) {
    if (isTrue(pop)) {
      var lastAnswer = answers.pop();
    }
    answers = shuffleArray(answers);
    if (isTrue(pop)) {
      answers.push(lastAnswer);
    }
  }

  const notFirst = questionNumber > 1;
  switch (questionType) {
    case "singleChoice":
    case "VI":
    case "VISqueeze":
      return (
        <SingleChoice
          answers={answers}
          onAnswerClick={({ nextQuestion, answerID }) =>
            next({
              nextQuestion,
              response: { question: questionNumber, answer: answerID }
            })
          }
          onBackClick={notFirst ? back : undefined}
        />
      );
    case "multipleChoice":
      return (
        <MultipleChoice
          answers={answers}
          onConfirmClick={(selected) =>
            next({
              nextQuestion: answers[0].revealQuestion,
              response: {
                question: questionNumber,
                answer: selected
              }
            })
          }
          onBackClick={notFirst ? back : undefined}
        />
      );
    case "dataCollection":
      return (
        <ContactDetailCollection
          contactDetails={contactDetails}
          onConfirmClick={(contactDetails) =>
            next({
              nextQuestion: answers[0].revealQuestion,
              response: {
                question: questionNumber,
                answer: 1,
                contactDetails
              }
            })
          }
          onBackClick={notFirst ? back : undefined}
        />
      );
    default:
      return <p>{iln.gettext("Unknown question type")}</p>;
  }
};

const QuestionTypes = ({
  questionType,
  questionNumber,
  answers,
  next,
  contactDetails,
  randomise,
  pop,
  back
}) => {
  const iln = useContext(TranslationContext);

  return getQuestion({
    questionType,
    next,
    back,
    questionNumber,
    answers,
    contactDetails,
    randomise,
    pop,
    iln
  });
};

QuestionTypes.propTypes = propTypes;

export default QuestionTypes;
