import "./styles.css";

import React, { useReducer } from "react";

import propTypes from "./propTypes";

const Checkbox = ({ name, value, checked, onChange, label, ...props }) => {
  const [internalChecked, toggleInternalChecked] = useReducer(
    (value) => !value,
    checked
  );

  return (
    <React.Fragment>
      <input
        type="checkbox"
        name={name}
        id={`${name}-checkbox`}
        value={value}
        checked={checked || internalChecked}
        onChange={(event) => {
          onChange(event);
          !checked && toggleInternalChecked();
        }}
        {...props}
      />
      {label && (
        <label htmlFor={`${name}-checkbox`} className="label">
          {label}
        </label>
      )}
    </React.Fragment>
  );
};

Checkbox.propTypes = propTypes;

export default Checkbox;
