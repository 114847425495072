import PropTypes from "prop-types";

const propTypes = {
  questionType: PropTypes.oneOf([
    "singleChoice",
    "dataCollection",
    "VI",
    "VISqueeze"
  ]).isRequired,
  questionNumber: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  contactDetails: PropTypes.exact({
    mobile: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired
  }).isRequired,
  randomise: PropTypes.oneOf([true, false, "true", "false"]),
  pop: PropTypes.oneOf([true, false, "true", "false"])
};

export default propTypes;
