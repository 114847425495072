import PropTypes from "prop-types";
import { household } from "propTypes/household.propType";
import { position } from "propTypes/position.propType";

const propTypes = {
  household: household,
  streetName: PropTypes.string.isRequired,
  position: position
};

export default propTypes;
