import "./styles.css";
import classnames from "helpers/classnames";
import React from "react";
import Placeholder from "components/common/Placeholder";

const HouseholdPlaceholder = () => {
  return (
    <React.Fragment>
      <Placeholder
        width={"100%"}
        height="45px"
        className={classnames("placeholder", "first-item")}
      />
      <Placeholder width={"100%"} height="45px" className={"placeholder"} />
      <Placeholder width={"100%"} height="45px" className={"placeholder"} />
      <Placeholder width={"100%"} height="45px" className={"placeholder"} />
      <Placeholder width={"100%"} height="45px" className={"placeholder"} />
      <Placeholder width={"100%"} height="45px" className={"placeholder"} />
    </React.Fragment>
  );
};

export default HouseholdPlaceholder;
