import "./styles.css";

import React, { useContext, useState } from "react";

import Button from "components/common/Button";
import { DebugModeContext } from "context/DebugMode/DebugMode";
import LogoutIcon from "./LogoutIcon.jsx";
import { clearToken } from "helpers/localStorage";
import propTypes from "./propTypes";
import { useNavigate } from "react-router-dom";

const Footer = ({ position }) => {
  const navigate = useNavigate();
  const [touchStart, setTouchStart] = useState();
  const { debug, toggleDebug } = useContext(DebugModeContext);

  const handleTouchStart = () => {
    setTouchStart(new Date());
  };

  const handleTouchEnd = () => {
    if (new Date() - touchStart > 1000) {
      toggleDebug();
    }
  };

  return (
    position && (
      <footer
        onMouseDown={() => handleTouchStart()}
        onMouseUp={() => handleTouchEnd()}
        className="App-footer"
      >
        <div />
        <div>{debug && `lat: ${position.lat} lng: ${position.lng}`}</div>
        <Button
          className="Logout-button"
          type="reset"
          onClick={() => {
            clearToken();
            navigate("/login");
          }}
        >
          <LogoutIcon fill="black" />
        </Button>
      </footer>
    )
  );
};

Footer.propTypes = propTypes;

export default Footer;
