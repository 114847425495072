import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

export const DebugModeContext = createContext();

const DebugModeProvider = ({ children }) => {
  const [debug, setDebug] = useState(false);

  const toggleDebug = () => {
    setDebug((debug) => !debug);
  };

  return (
    <DebugModeContext.Provider value={{ debug, toggleDebug }}>
      {children}
    </DebugModeContext.Provider>
  );
};

DebugModeProvider.propTypes = {
  children: PropTypes.node
};

export default DebugModeProvider;
