import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const Button = ({
  disabled = false,
  children,
  className,
  type = "default",
  onClick,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        type === "reset" ? "Button-reset" : "Button",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = propTypes;

export default Button;
