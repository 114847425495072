import "./styles.css";

import HeadIcon from "components/common/Icons/HeadIcon";
import React from "react";
import propTypes from "./propTypes";

const HouseholdCount = ({ numberOfOccupants }) => {
  if (numberOfOccupants === 0) return;

  return (
    <div className="Household-count">
      {[...Array(numberOfOccupants).keys()].slice(0, 4).map((item) => {
        return (
          <HeadIcon
            key={item}
            style={{
              width: 30,
              opacity: (4 - item) * 0.25,
              marginTop: -item * 2,
              marginRight: -22
            }}
          />
        );
      })}
    </div>
  );
};

HouseholdCount.propTypes = propTypes;

export default HouseholdCount;
