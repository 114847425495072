import "./styles.css";

import React, { useContext, useState } from "react";

import Button from "components/common/Button";
import ButtonList from "components/common/ButtonList";
import Checkbox from "components/common/Checkbox";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";

const MultipleChoice = ({ onConfirmClick, onBackClick, answers }) => {
  const [selected, setSelected] = useState([]);
  const iln = useContext(TranslationContext);

  return (
    <div className="Single-choice-question">
      <ButtonList>
        {answers.map(({ text, answerID }) => (
          <div key={answerID}>
            <Button>
              <Checkbox
                name={`${answerID}`}
                value={answerID}
                label={text}
                checked={selected.includes(answerID)}
                onChange={({ target: { checked, value } }) =>
                  setSelected(
                    checked
                      ? [...selected, value]
                      : selected.filter((s) => s !== value)
                  )
                }
              />
            </Button>
          </div>
        ))}
      </ButtonList>
      <div className="Buttons-wrapper">
        {onBackClick && (
          <Button className="Back-button" onClick={onBackClick}>
            {iln.gettext("Back")}
          </Button>
        )}
        <Button
          className="Back-button"
          disabled={selected.length === 0}
          onClick={() => onConfirmClick(selected)}
        >
          {iln.gettext("Confirm")}
        </Button>
      </div>
    </div>
  );
};

MultipleChoice.propTypes = propTypes;

export default MultipleChoice;
