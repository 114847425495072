/**
 * How often the React state is updated with the latest GPS position.
 *
 * If the state updates with every GPS update then React constantly
 * re-renders which leads to poor performance and reduced battery life
 * of mobile devices. Check every x seconds instead
 */
export const positionTimeoutInterval = 5 * 1000; // 5 Seconds

/**
 * Logout interval.... TODO
 */
export const logoutInterval = 1000; // 1 second

/**
 * The minimum distance the user must travel for the GPS position
 * stored in React state to update
 */
export const positionDistanceInterval = 0.005; // 5 metres
