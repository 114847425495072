/**
 * Joins multiple string classnames together
 *  @param {string} args - All the parameters passed to the function. None string values are filtered out
 *  @returns {string} - A single string containing all the parameter strings joined with a space
 */

const classnames = (...args) =>
  args
    .reduce(
      (accumulation, current) =>
        typeof current === "string"
          ? `${accumulation} ${current}`
          : accumulation,
      ""
    )
    .trim();

export default classnames;
