import propTypes from "prop-types";

export const household = propTypes.shape({
  address: propTypes.shape({
    address1: propTypes.string.isRequired,
    address2: propTypes.string.isRequired,
    postcode: propTypes.string.isRequired,
    state: propTypes.string.isRequired,
    town: propTypes.string.isRequired,
    unitNumber: propTypes.string.isRequired,
    streetNumber: propTypes.string.isRequired,
    streetName: propTypes.string.isRequired
  }),
  lat: propTypes.string.isRequired,
  lng: propTypes.string.isRequired,
  lastVisited: propTypes.string,
  answered: propTypes.bool,
  constituents: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      firstname: propTypes.string.isRequired,
      surname: propTypes.string.isRequired,
      knownAs: propTypes.string,
      dob: propTypes.string,
      gender: propTypes.string,
      votingIntentions: propTypes.array
    })
  )
});
