import "./styles.css";

import Button from "components/common/Button";
import Page from "components/app/Page";
import React from "react";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";
import { useContext } from "react";

const NoQuestion = ({ onBackClick }) => {
  const iln = useContext(TranslationContext);

  return (
    <Page className="No-question-page">
      <h2>{iln.gettext("There are no available surveys")}</h2>
      <Button onClick={onBackClick}>{iln.gettext("Back")}</Button>
    </Page>
  );
};

NoQuestion.propTypes = propTypes;

export default NoQuestion;
