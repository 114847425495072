import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const ButtonList = ({ children, className, ...props }) => {
  return (
    <div className={classnames("Button-list", className)} {...props}>
      {children}
    </div>
  );
};

ButtonList.propTypes = propTypes;

export default ButtonList;
