import DebugModeProvider from "context/DebugMode/";
import { Navigate, Route, Routes } from "react-router-dom";
import { getHadLocationPermissionBefore, getToken } from "helpers/localStorage";

import Footer from "components/app/Footer";
import Header from "components/app/Header";
import HouseholdSelectPage from "components/HouseholdSelect";
import LoginPage from "components/Login";
import Main from "components/app/Main";
import ModalProvider from "context/Modal";
import PermissionsPage from "components/Permissions";
import PrivateRoutes from "components/app/PrivateRoute/PrivateRoutes";
import React from "react";
import StreetSelect from "components/StreetSelect";
import TranslationProvider from "context/Translation";
import VoterPage from "components/Voter";
import useAutoLogout from "hooks/useAutoLogout";
import useWatchPosition from "hooks/useWatchPosition";

const App = () => {
  const { position, requestLocationPermission } = useWatchPosition();
  useAutoLogout();

  return (
    <TranslationProvider>
      <ModalProvider>
        <DebugModeProvider>
          <Header />
          <Main>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route
                path="/login"
                element={
                  getToken() ? <Navigate to="/permissions" /> : <LoginPage />
                }
              />
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/permissions"
                  element={
                    getHadLocationPermissionBefore() ? (
                      <Navigate to="/streets" />
                    ) : (
                      <PermissionsPage
                        requestPermissions={requestLocationPermission}
                      />
                    )
                  }
                />
                <Route
                  path="/streets"
                  element={<StreetSelect position={position} />}
                />
                <Route
                  path="/street/:town/:streetName/*"
                  element={<HouseholdSelectPage position={position} />}
                />
                <Route path="/voter" element={<VoterPage />} />
              </Route>
              <Route path={"*"} element={<Navigate to={"/login"} />} />
            </Routes>
          </Main>
          <Footer position={position} />
        </DebugModeProvider>
      </ModalProvider>
    </TranslationProvider>
  );
};

export default App;
