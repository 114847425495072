import "./styles.css";
import React from "react";
import propTypes from "./propTypes";

const Alert = ({ children }) => {
  return <div className={"alert"}>{children}</div>;
};

Alert.propTypes = propTypes;

export default Alert;
