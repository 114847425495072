import "./styles.css";

import React, { useContext, useEffect, useState } from "react";

import Button from "components/common/Button";
import ButtonList from "components/common/ButtonList";
import ErrorText from "components/common/ErrorText";
import Page from "components/app/Page";
import Placeholder from "components/common/Placeholder";
import { TranslationContext } from "context/Translation";
import api from "api";
import distanceToPositionSort from "helpers/distanceToPositionSort";
import propTypes from "./propTypes";
import { useNavigate } from "react-router-dom";

const StreetSelectPage = ({ position }) => {
  const iln = useContext(TranslationContext);
  const navigate = useNavigate();
  const [streets, setStreets] = useState();

  useEffect(() => {
    position &&
      api.getStreetsNearMe(position).then(({ streets }) => setStreets(streets));
  }, [position]);

  return (
    <Page>
      <h1>{iln.gettext("Select Street")}</h1>
      <p>
        {iln.gettext(
          "Select the street you are currently on to view a list of nearby households with registered voters"
        )}
      </p>
      {!streets ? (
        <Placeholder width={"100%"} height="calc(100vh - 300px)" />
      ) : streets.length <= 0 ? (
        <ErrorText>{iln.gettext("No nearby streets")}</ErrorText>
      ) : (
        <ButtonList>
          {streets
            .sort(distanceToPositionSort(position))
            .map(({ street, town }, idx) => (
              <Button
                key={idx}
                onClick={() => navigate(`/street/${town}/${street}`)}
              >
                {street + ", " + town}
              </Button>
            ))}
        </ButtonList>
      )}
    </Page>
  );
};

StreetSelectPage.propTypes = propTypes;

export default StreetSelectPage;
