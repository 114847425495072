import "../styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "../propTypes";

const ArrowIcon = ({ position = "left", direction = "left", className }) => {
  return (
    <div
      className={classnames(
        className,
        direction === "right" ? "direction-right" : null,
        position === "left" ? "icon-left" : "icon-right"
      )}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M 16.507 23.601 C 15.889 23.601 15.293 23.356 14.857 22.916 L 5.513 13.573 C 4.6 12.661 4.6 11.183 5.513 10.269 L 14.857 0.925 C 16.106 -0.369 18.286 0.176 18.781 1.905 C 19.018 2.736 18.781 3.629 18.159 4.229 L 10.467 11.921 L 18.159 19.613 C 19.43 20.887 18.849 23.057 17.11 23.521 C 16.914 23.575 16.711 23.601 16.507 23.601 Z"
          fill="grey"
        ></path>
      </svg>
    </div>
  );
};

ArrowIcon.propTypes = propTypes;

export default ArrowIcon;
