import "./styles.css";

import React from "react";
import logo from "./logo.svg";

const Header = () => {
  return (
    <header className="App-header">
      <img className="Logo" src={logo} alt="Company logo" />
      <div className="Logo-text">VoterID</div>
    </header>
  );
};

export default Header;
