import "./leaflet.css";

import React, { useEffect, useRef, useState } from "react";

import CustomMarker from "./CustomMarker";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import propTypes from "./propTypes";

const Map = ({
  center,
  height = "100vh",
  width = "100%",
  markers = [],
  position
}) => {
  const [follow] = useState(true);
  const mapRef = useRef();

  useEffect(() => {
    follow && mapRef.current && mapRef.current.panTo(position);
  }, [position]);

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0"
      />
      <MapContainer
        ref={mapRef}
        center={
          center
            ? [(center.lat, center.lng)]
            : position
            ? [position.lat, position.lng]
            : { lat: 52.063, lng: -1.626 }
        }
        zoom={18}
        maxZoom={20}
        scrollWheelZoom={false}
        style={{ height, width }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {(position
          ? [{ ...position, icon: "position" }, ...markers]
          : markers
        ).map(({ lat, lng, popup, icon, color }, idx) => (
          <CustomMarker
            key={idx}
            lat={lat}
            lng={lng}
            icon={icon}
            color={color}
            popup={popup}
          />
        ))}
      </MapContainer>
    </React.Fragment>
  );
};

Map.propTypes = propTypes;

export default Map;
