const oddSort =
  (asc) =>
  ({ address: { streetNumber: a } }, { address: { streetNumber: b } }) => {
    a = parseInt(a);
    b = parseInt(b);

    if (a % 2 !== 1 && b % 2 === 1) {
      return 1;
    }
    if (a < b) {
      return asc ? -1 : 1;
    }

    if (a % 2 === 1 && b % 2 !== 1) {
      return -1;
    }
    if (a > b) {
      return asc ? 1 : -1;
    }

    return 0;
  };

export default oddSort;
