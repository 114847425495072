import distanceBetweenLatLngs from "./distanceBetweenLatLngs";

/**
 * A function that sorts an array based on distance to a single point, closest first
 * @param {object} position The position that the distances are measured from
 * @param {number} position.lat The latitude of the position that the distances are measured from
 * @param {number} position.lng The longitude of the position that the distances are measured from
 * @returns {function} The sort function whiich accepts a and b, both should be ojects
 * @param {object} a The first element for comparision
 * @param {number} a.lat The latitude of the first element
 * @param {number} a.lng The longitude of the first element
 * @param {object} b The second element for comparision
 * @param {number} b.lat The latitude of the second element
 * @param {number} b.lng The longitude of the second element
 * @returns {Array} A reference to the original array, now sorted

 */
const distanceToPositionSort =
  (position, sortAscending = true) =>
  ({ lat: latA, lng: lngA }, { lat: latB, lng: lngB }) => {
    const { lat: positionLat, lng: positionLng } = position;

    const distanceA = distanceBetweenLatLngs(
      latA,
      lngA,
      positionLat,
      positionLng
    );
    const distanceB = distanceBetweenLatLngs(
      latB,
      lngB,
      positionLat,
      positionLng
    );

    if (distanceA < distanceB) return sortAscending ? -1 : 1;
    if (distanceA > distanceB) return sortAscending ? 1 : -1;
    return 0;
  };

export default distanceToPositionSort;
