import "./icons.css";

import L from "leaflet";
import classnames from "helpers/classnames";

export const defaultIcon = (color) =>
  L.divIcon({
    className: classnames("default-map-pin", `icon-${color}`),
    html: "<span />"
  });

export const positionIcon = (color) =>
  L.divIcon({
    className: classnames("position-map-pin", `icon-${color}`),
    html: "<span />"
  });

export const houseIcon = (color) =>
  L.divIcon({
    className: classnames(
      "house-map-pin",
      "material-symbols-rounded",
      `icon-${color}`
    ),
    html: "<span >"
  });
