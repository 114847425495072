export default function phoneNumberToInternational(value) {
  if (value == "") {
    return value;
  }

  value = value.replaceAll(" ", "");

  if (value.substr(0, 1) === "0") {
    value = value.replace("0", "");
  }

  if (value.substr(0, 3) !== "+61") {
    value = "+61" + value;
  }

  return value;
}
