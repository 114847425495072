import React, { useContext } from "react";

import Button from "components/common/Button";
import ButtonList from "components/common/ButtonList";
import { ModalContext } from "context/Modal";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";
import { setSortOptions } from "helpers/localStorage";

const ChangeSortModal = ({ setSortType, setSortAscending }) => {
  const iln = useContext(TranslationContext);
  const { modalActions } = useContext(ModalContext);

  return (
    <ButtonList>
      <Button
        onClick={() => {
          setSortType("distance");
          setSortAscending(true);
          modalActions.close();
          setSortOptions({ type: "distance" });
        }}
      >
        {iln.gettext("Distance")}
      </Button>
      <Button
        onClick={() => {
          setSortType("numerical");
          setSortAscending(true);
          modalActions.close();
          setSortOptions({ type: "numerical", ascending: true });
        }}
      >
        {iln.gettext("Street Number Ascending")}
      </Button>
      <Button
        onClick={() => {
          setSortType("numerical");
          setSortAscending(false);
          modalActions.close();
          setSortOptions({ type: "numerical", ascending: false });
        }}
      >
        {iln.gettext("Street Number Descending")}
      </Button>
      <Button
        onClick={() => {
          setSortType("odd");
          setSortAscending(true);
          modalActions.close();
          setSortOptions({ type: "odd", ascending: true });
        }}
      >
        {iln.gettext("Odds / Evens Ascending")}
      </Button>
      <Button
        onClick={() => {
          setSortType("odd");
          setSortAscending(false);
          modalActions.close();
          setSortOptions({ type: "odd", ascending: false });
        }}
      >
        {iln.gettext("Odds / Evens Descending")}
      </Button>
      <Button
        onClick={() => {
          setSortType("even");
          setSortAscending(true);
          modalActions.close();
          setSortOptions({ type: "even", ascending: true });
        }}
      >
        {iln.gettext("Even / Odds Ascending")}
      </Button>
      <Button
        onClick={() => {
          setSortType("even");
          setSortAscending(false);
          modalActions.close();
          setSortOptions({ type: "even", ascending: false });
        }}
      >
        {iln.gettext("Even / Odds Descending")}
      </Button>
    </ButtonList>
  );
};

ChangeSortModal.propTypes = propTypes;

const ChangeSortButton = ({ setSortType, setSortAscending }) => {
  const iln = useContext(TranslationContext);
  const { modalActions } = useContext(ModalContext);

  return (
    <Button
      onClick={() =>
        modalActions.open({
          title: iln.gettext("Change Sort"),
          component: (
            <ChangeSortModal
              setSortType={setSortType}
              setSortAscending={setSortAscending}
            />
          )
        })
      }
    >
      {iln.gettext("Change Sort")}
    </Button>
  );
};

ChangeSortButton.propTypes = propTypes;

export default ChangeSortButton;
