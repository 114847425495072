import PropTypes from "prop-types";

const propTypes = {
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  popup: PropTypes.node,
  icon: PropTypes.oneOf(["house", "position"]),
  color: PropTypes.oneOf(["red", "orange", "blue", "green"])
};

export default propTypes;
