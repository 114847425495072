import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const Card = ({ children, className, ...props }) => {
  return (
    <div {...props} className={classnames("Card", className)}>
      {children}
    </div>
  );
};

Card.propTypes = propTypes;

export default Card;
