import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const ErrorText = ({ className, children, ...props }) => {
  return (
    <p className={classnames("Error-text", className)} {...props}>
      {children}
    </p>
  );
};

ErrorText.propTypes = propTypes;

export default ErrorText;
