import PropTypes from "prop-types";

const propTypes = {
  type: PropTypes.oneOf(["text", "email", "number", "password"]),
  className: PropTypes.shape({
    input: PropTypes.string,
    error: PropTypes.string
  }),
  keepErrorSpacing: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string
};

export default propTypes;
