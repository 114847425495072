/**
 * accepts the latitude and longitude of 2 points and calculates the distance between them
 * @param {number} lat1 The latitude of the first point
 * @param {number} lng1 The longitude of the first point
 * @param {number} lat2 The latitude of the second point
 * @param {number} lng2 The longitude of the second point
 * @returns {number} The distance between the two points
 */
function haversineFormula(lat1, lng1, lat2, lng2) {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

const distanceBetweenLatLngs = (
  lat1,
  lng1,
  lat2,
  lng2,
  formula = "haversine"
) => {
  if (formula === "haversine") return haversineFormula(lat1, lng1, lat2, lng2);
};

export default distanceBetweenLatLngs;
