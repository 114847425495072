import "./styles.css";

import React, { useReducer } from "react";

import Button from "components/common/Button";
import Card from "components/common/Card";
import { createContext } from "react";
import propTypes from "./propTypes";

export const ModalContext = createContext();

const initState = {
  title: "",
  component: undefined
};

const modalReducer = (state, { type, payload }) => {
  switch (type) {
    case "MODAL_CLOSE":
      return initState;
    case "MODAL_OPEN":
      return payload;
    case "MODAL_UPDATE":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const ModalProvider = ({ children }) => {
  const [modalState, modalDispatcher] = useReducer(modalReducer, initState);

  const modalActions = {
    close: () => modalDispatcher({ type: "MODAL_CLOSE" }),
    open: (payload) => modalDispatcher({ type: "MODAL_OPEN", payload }),
    update: (payload) => modalDispatcher({ type: "MODAL_UPDATE", payload })
  };

  return (
    <ModalContext.Provider value={{ modalState, modalActions }}>
      {modalState.component && (
        <div className="Modal-container">
          <Card className="Modal-card">
            <Button
              className="Modal-cross"
              type="reset"
              onClick={() => modalActions.close()}
            >
              x
            </Button>
            {modalState.title && (
              <h2 className="Modal-title">{modalState.title}</h2>
            )}
            {modalState.component}
          </Card>
        </div>
      )}
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = propTypes;

export default ModalProvider;
