import "./styles.css";

import React from "react";
import propTypes from "./propTypes";

const Main = ({ children }) => {
  return <main className="Main-container">{children}</main>;
};

Main.propTypes = propTypes;

export default Main;
