import PropTypes from "prop-types";

const propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      answerID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ).isRequired
};

export default propTypes;
