import "./styles.css";

import React, { useContext, useState } from "react";

import ArrowIcon from "components/common/Icons/ArrowIcon";
import Button from "components/common/Button";
import ButtonList from "components/common/ButtonList";
import { ModalContext } from "context/Modal";
import Spinner from "components/common/Spinner";
import { TranslationContext } from "context/Translation";
import api from "api";
import { getVotingIntentions } from "helpers/localStorage";
import propTypes from "./propTypes";

const INTENTIONS = ["Strong", "Weak", "Persuadable", "Unknown"];
const UNDECIDED = ["Undecided", "Swinger"];

const NewVI = ({ constituentID, updateVI }) => {
  const iln = useContext(TranslationContext);
  const { modalActions } = useContext(ModalContext);

  const [selectedParty, setSelectedParty] = useState();
  const [loading, setLoading] = useState();
  const votingIntentions = getVotingIntentions().filter(
    (p) => !UNDECIDED.includes(p)
  );

  const uploadVI = (intention, index) => {
    setLoading(index);
    api
      .setVotingIntention(constituentID, intention)
      .then(() => {
        updateVI(intention);
        modalActions.close();
      })
      .finally(() => setLoading());
  };

  return (
    <React.Fragment>
      <ButtonList>
        {!selectedParty
          ? [...UNDECIDED, ...votingIntentions].map((party, index) => {
              const includesUndecided = !UNDECIDED.includes(party);
              return (
                <Button
                  key={party}
                  className="VI-button"
                  onClick={() =>
                    includesUndecided
                      ? setSelectedParty(party)
                      : uploadVI(party, index)
                  }
                >
                  {loading !== index ? (
                    <div>
                      {party}
                      {includesUndecided && (
                        <ArrowIcon
                          className="rightArrow"
                          position="icon-right"
                          direction="right"
                        />
                      )}
                    </div>
                  ) : (
                    <Spinner size="medium" />
                  )}
                </Button>
              );
            })
          : INTENTIONS.map((intention, index) => (
              <Button
                key={intention}
                className="VI-button"
                onClick={() => uploadVI(`${selectedParty} ${intention}`, index)}
              >
                {loading !== index ? (
                  `${selectedParty} ${intention}`
                ) : (
                  <Spinner size="medium" />
                )}
              </Button>
            ))}
      </ButtonList>
      <br />
      {selectedParty && (
        <Button
          className="CancelButton button"
          onClick={() => setSelectedParty()}
        >
          <div>{iln.gettext("Back")}</div>
        </Button>
      )}
    </React.Fragment>
  );
};

NewVI.propTypes = propTypes;
export default NewVI;
