import "./styles.css";
import React from "react";
import propTypes from "./propTypes";

const ButtonBar = ({ children }) => {
  return <div className={"Button-bar"}>{children}</div>;
};

ButtonBar.propTypes = propTypes;

export default ButtonBar;
