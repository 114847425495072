import PropTypes from "prop-types";
import { position } from "propTypes/position.propType";

const propTypes = {
  center: PropTypes.exact({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  position: position,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      icon: PropTypes.oneOf(["house"]),
      color: PropTypes.oneOf(["red", "orange", "blue", "green"]),
      popup: PropTypes.node
    })
  )
};

export default propTypes;
