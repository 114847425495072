import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["default", "reset"]),
  onClick: PropTypes.func
};

export default propTypes;
