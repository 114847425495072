import "./styles.css";

import ArrowIcon from "components/common/Icons/ArrowIcon";
import Button from "components/common/Button";
import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ children, className }) => {
  const navigate = useNavigate();

  return (
    <div className={classnames("Page-header", className)}>
      <Button type="reset" className="Back-button" onClick={() => navigate(-1)}>
        <ArrowIcon />
      </Button>
      {children}
    </div>
  );
};

PageHeader.propTypes = propTypes;

export default PageHeader;
