import "./styles.css";

import PageHeader from "./PageHeader";
import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const Page = ({ children, className, header }) => {
  return (
    <React.Fragment>
      {header && <PageHeader>{header}</PageHeader>}
      <div className={classnames("Page", className)}>{children}</div>
    </React.Fragment>
  );
};

Page.propTypes = propTypes;

export default Page;
