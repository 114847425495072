import PropTypes from "prop-types";

const propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  contactDetails: PropTypes.exact({
    email: PropTypes.string,
    telephone: PropTypes.string,
    mobile: PropTypes.string
  })
};

export default propTypes;
