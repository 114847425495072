import { setToken, getToken } from "helpers/localStorage";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const getJwtExpiry = (token) => {
  return Math.trunc(JSON.parse(atob(token.split(".")[1])).exp);
};

const useAutoLogout = () => {
  const navigate = useNavigate();
  const setTimeoutRef = useRef();

  const token = getToken();

  const startSessionTimeout = () => {
    clearTimeout(setTimeoutRef.current);
    if (token) {
      const jwtExpiry = getJwtExpiry(token);
      const currentDateTimeInSeconds = Math.trunc(Date.now() / 1000);
      const sessionExpired = currentDateTimeInSeconds >= jwtExpiry;
      const sessionLengthInSeconds = jwtExpiry - currentDateTimeInSeconds;

      if (sessionExpired) {
        setToken("");
        navigate("/login");
      } else {
        setTimeoutRef.current = setTimeout(() => {
          startSessionTimeout();
        }, sessionLengthInSeconds * 1000);
      }
    }
  };

  useEffect(() => {
    startSessionTimeout();
  }, [token]);
};

export default useAutoLogout;
