const numericalSort =
  (ascending) =>
  ({ address: { streetNumber: a } }, { address: { streetNumber: b } }) => {
    a = parseInt(a);
    b = parseInt(b);

    if (a < b) return ascending ? -1 : 1;
    if (a > b) return ascending ? 1 : -1;
    return 0;
  };

export default numericalSort;
