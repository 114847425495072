import PropTypes from "prop-types";

const propTypes = {
  message: PropTypes.node.isRequired,
  surveyID: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        question: PropTypes.number.isRequired,
        answer: PropTypes.number.isRequired
      }),
      PropTypes.exact({
        question: PropTypes.number.isRequired,
        answer: PropTypes.number.isRequired,
        contactDetails: PropTypes.exact({
          email: PropTypes.string,
          telephone: PropTypes.string,
          mobile: PropTypes.string
        })
      })
    ])
  )
};

export default propTypes;
