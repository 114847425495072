import { defaultIcon, houseIcon, positionIcon } from "./icons";

import { Marker } from "react-leaflet/Marker";
import { Popup } from "react-leaflet/Popup";
import React from "react";
import propTypes from "./propTypes";

const getIcon = (icon, color) => {
  switch (icon) {
    case "house":
      return houseIcon(color);
    case "position":
      return positionIcon();
    default:
      return defaultIcon();
  }
};

const CustomMarker = ({ icon, lat, lng, popup, color = "blue" }) => {
  return (
    <Marker position={[lat, lng]} icon={getIcon(icon, color)}>
      {popup && <Popup>{popup}</Popup>}
    </Marker>
  );
};

CustomMarker.propTypes = propTypes;

export default CustomMarker;
