import "./styles.css";

import React, { useContext, useState } from "react";
import { setSurvey, setVotingIntentions } from "helpers/localStorage";

import Button from "components/common/Button";
import ButtonSpinner from "components/common/ButtonSpinner/ButtonSpinner";
import ErrorText from "components/common/ErrorText";
import Input from "components/common/Input";
import { TranslationContext } from "context/Translation";
import api from "api";
import hash from "md5";
import { isEmail } from "helpers/isEmail";
import { useNavigate } from "react-router-dom";

const saveSurveyToLocalStorage = ({ surveyJSON, ...rest } = {}) => {
  if (surveyJSON) {
    const survey = {
      ...rest,
      surveyContent: JSON.parse(surveyJSON)
    };
    setSurvey(survey);
  } else {
    setSurvey({});
  }
};

const savePartiesToLocalStorage = (parties = []) =>
  setVotingIntentions(parties);

const LoginPage = () => {
  const iln = useContext(TranslationContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailInputError, setEmailInputError] = useState("");
  const [passwordInputError, setPasswordInputError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    if (!email || !password || !isEmail(email)) return;

    setLoading(true);

    api
      .auth({
        email: email,
        password: hash(password)
      })
      .then(({ data: { votingIntentions, surveys } }) => {
        saveSurveyToLocalStorage(surveys || {});
        savePartiesToLocalStorage(votingIntentions || []);
        navigate("/permissions");
      })
      .catch(({ response }) => {
        setLoginError(response.data.error);
        setPassword("");
      })
      .finally(() => setLoading(false));
  };

  const handleEmailAddressInputChange = ({ target: { value } }) => {
    setEmail(value);
    setLoginError("");

    switch (true) {
      case !value:
        setEmailInputError("Please enter your email address");
        break;
      case !isEmail(value):
        setEmailInputError("Please enter valid email address");
        break;
      default:
        setEmailInputError("");
    }
  };

  const handlePasswordInputChange = ({ target: { value } }) => {
    setPassword(value);
    setLoginError("");

    switch (true) {
      case !value:
        setPasswordInputError("Please enter your password");
        break;
      default:
        setPasswordInputError("");
    }
  };

  return (
    <div className="Login-page">
      <h1>{iln.gettext("Door Knocking Login")}</h1>
      <Input
        value={email}
        onChange={handleEmailAddressInputChange}
        className={{ input: "Login-email-input" }}
        placeholder={iln.gettext("Your Email")}
        type="email"
        errorText={emailInputError}
        keepErrorSpacing
      />
      <Input
        value={password}
        onChange={handlePasswordInputChange}
        className={{ input: "Login-password-input" }}
        placeholder={iln.gettext("Your Password")}
        type="password"
        errorText={passwordInputError}
        keepErrorSpacing
      />
      <Button
        disabled={
          !!emailInputError ||
          !email ||
          !!passwordInputError ||
          !password ||
          !isEmail(email)
        }
        className="Login-button"
        onClick={() => handleLogin()}
      >
        {loading ? <ButtonSpinner /> : iln.gettext("Login")}
      </Button>
      <ErrorText>{loginError}</ErrorText>
    </div>
  );
};

export default LoginPage;
