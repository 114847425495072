import "./styles.css";

import React, { useState } from "react";
import {
  getCurrentSurveyQuestion,
  getSurvey,
  getSurveyResults,
  getVoter,
  setCurrentSurveyQuestion,
  setSurveyResults
} from "helpers/localStorage";

import NoSurveys from "./NoSurveys";
import QuestionTypes from "./QuestionTypes";
import SurveyComplete from "./SurveyComplete";
import { useNavigate } from "react-router-dom";

let responses = getSurveyResults() || {};

const questionHistory = [];

const searchQuestions = (questions, questionNumber) =>
  questions.find((q) => q.questionNo === questionNumber) || { answers: [] };

const Survey = () => {
  const { id: constituentID, email, mobile, telephone } = getVoter();

  const navigate = useNavigate();
  const [questionNumber, setQuestionNumber] = useState(
    getCurrentSurveyQuestion() || 1
  );

  const {
    surveyContent,
    surveyContent: {
      finishMessage: { text: finishingMessageText },
      questions
    },
    id: surveyID
  } = getSurvey();

  const { question, questionType, answers, pop, randomise } = searchQuestions(
    questions,
    questionNumber
  );

  if (!surveyContent) {
    return <NoSurveys onBackClick={() => navigate(-1)} />;
  }

  if (questionNumber > questions.length || !question) {
    return (
      <SurveyComplete
        message={finishingMessageText}
        surveyID={surveyID}
        constituentID={constituentID}
        results={Object.values(responses)}
      />
    );
  }

  const next = ({ nextQuestion, response }) => {
    questionHistory.push(questionNumber);
    setQuestionNumber(+nextQuestion);
    setCurrentSurveyQuestion(+nextQuestion); // update local storage
    responses = { ...responses, [questionNumber]: response };
    setSurveyResults(responses); // update localStorage
  };

  const back = () => {
    const previousQuestionNumber = questionHistory.pop() || 1;
    setCurrentSurveyQuestion(previousQuestionNumber); // update local storage
    setQuestionNumber(previousQuestionNumber);
    delete responses[previousQuestionNumber];
    setSurveyResults(responses); // update localStorage
  };

  return (
    <div className="Survey">
      <h3>{question}</h3>
      <QuestionTypes
        questionNumber={questionNumber}
        questionType={questionType}
        answers={answers}
        next={next}
        back={back}
        contactDetails={{ mobile, email, telephone }}
        pop={pop}
        randomise={randomise}
      />
    </div>
  );
};

export default Survey;
