import axiosInstance from "./config";

/**
 * Auth:
 * @param {{email: String, password: String}} credentials - Users login information.
 * @returns {Promise} A promise that resolves to an object containing a session token.
 */
const auth = ({ email, password }) => {
  return axiosInstance.post("/auth", {
    email,
    password,
    subdomain: window.location.host.replace(
      /-dk.caseworkermp.com|-dk.voter.id|.localhost:?[0-9]*/g,
      ""
    )
  });
};

/**
 * Streets near me:
 * @param {{lat: String, lng: String}} location - Users current location.
 * @returns {Promise<{street: Array<String>}>} A promise that resolves to an object containing a list of street close to passed in location.
 */
const getStreetsNearMe = ({ lat, lng }) => {
  return axiosInstance
    .post("/streetsNearMe", {
      lat,
      lng
    })
    .then(({ data }) => data);
};

/**
 * Household:
 * @param {{streetName: String, town: String}} Household - Selected household street and suburb.
 * @returns {Promise<{households: address: <String>, constituents: [{ID: Number, firstname: String, surname: String, gender: String, dob: String}]}>} A promise that resolves to an object containing a list of households.
 */
const getHouseholds = ({ streetName, town }) => {
  return axiosInstance
    .post("/households", {
      selectedStreet: streetName,
      selectedTown: town
    })
    .then(({ data }) => data);
};

/**
 * Get Voting Intention endpoint:
 * @returns {string} A promise that resolves to a list of voting intentions.
 */
const getVotingIntentions = () => {
  return axiosInstance.get("/votingIntentions").then(({ data }) => data);
};

/**
 * Save Voting Intention endpoint:
 * @param {number} constituentID - constituentID against which the voting intention will be saved.
 * @param {string} votingIntention - voting intention to be saved for the constituent.
 * @param {number} caseworker - The id of the caseworker.
 * @param {string} source - Survey name.
 * @returns {string} A promise that resolves to a message if the intention was saved successfully.
 */
const setVotingIntention = (
  constituentID,
  votingIntention,
  caseworker,
  source
) => {
  return axiosInstance
    .post("/votingIntentions", {
      constituentID: constituentID,
      votingIntention: votingIntention,
      caseworker: caseworker,
      source: source
    })
    .then(({ data }) => data);
};

const submitSurveyResults = (payload) => {
  return axiosInstance.post("/survey/results", payload);
};

/**
 * Constituent did not participate endpoint:
 * @param { constituentID: string} constituentId - The constituent that did not participate.
 * @param { surveyID: String } surveyId - The Id of the survey that the constituent did not participate in.
 * @returns {string} A promise that resolves to a message if the "did not participate" was saved successfully.
 * */

const didNotParticipateConstituent = (constituentId, surveyId) => {
  return axiosInstance
    .post("/survey/didNotParticipate", {
      constituentID: constituentId,
      surveyID: surveyId
    })
    .then((data) => data);
};

/**
 * Household did not participate endpoint:
 * @param { address1: string} address1 - The first line of the household's address.
 * @param { town: String } town - The town of the household.
 * @param { state: String } state - The state that the town is in.
 * @returns {string} A promise that resolves to a message if the "did not participate" was saved successfully.
 * */

const didNotParticipateHouseHold = (
  address1,
  address2,
  town,
  postcode,
  state
) => {
  return axiosInstance
    .post("/household/refused", { address1, address2, town, postcode, state })
    .then((data) => data);
};

/**
 * Household did not answer endpoint:
 * @param { address1: string} address1 - The first line of the household's address.
 * @param { town: String } town - The town of the household.
 * @param { state: String } state - The state that the town is in.
 * @returns {string} A promise that resolves to a message if the "did not participate" was saved successfully.
 * */

const didNotAnswer = (address1, address2, town, postcode, state) => {
  return axiosInstance
    .post("/household/didNotAnswer", {
      address1,
      address2,
      town,
      postcode,
      state
    })
    .then((data) => data);
};

/**
 * Get lists of households near a position:
 * @param { lat:String , lng:String} position - The first line of the household's address.
 * */

const getNearbyHouseholds = (position) => {
  return axiosInstance
    .post("/map/households", {
      lat: position.lat,
      lng: position.lng
    })
    .then(({ data }) => data);
};

const api = {
  auth,
  submitSurveyResults,
  getStreetsNearMe,
  getHouseholds,
  getVotingIntentions,
  setVotingIntention,
  didNotParticipateConstituent,
  didNotParticipateHouseHold,
  didNotAnswer,
  getNearbyHouseholds
};
export default api;
