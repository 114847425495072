import "./styles.css";

import React, { createContext, useEffect, useState } from "react";

import ErrorText from "components/common/ErrorText";
import Spinner from "components/common/Spinner";
import ilnConstruct from "gettext.js";
import propTypes from "./propTypes";

export const TranslationContext = createContext();

let language = "en";
let locale = "AU";

const TranslationProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [iln, setIln] = useState();

  useEffect(() => {
    fetch(`/locales/${language}_${locale}.json`)
      .then((res) => res.json())
      .then((json) => {
        const iln = ilnConstruct();
        iln.loadJSON(json);
        iln.setLocale(`${language}_${locale}`);
        setIln(iln);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  }, []);

  return loading || error ? (
    <div className="Translation-loading">
      {error ? (
        <ErrorText>
          There was an error loading the translation file
          <br />
          <br />
          {error.message}
        </ErrorText>
      ) : (
        <div>
          <Spinner key="spinner" size="large" />
          <div key="message">Translation Loading</div>
        </div>
      )}
    </div>
  ) : (
    <TranslationContext.Provider value={iln}>
      {children}
    </TranslationContext.Provider>
  );
};

TranslationProvider.propTypes = propTypes;

export default TranslationProvider;
