import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const Spinner = ({ className, size = "medium", ...props }) => {
  return (
    <div
      className={classnames(
        "Spinner",
        size === "small"
          ? "Spinner-small"
          : size === "medium"
          ? "Spinner-medium"
          : size === "large"
          ? "Spinner-large"
          : "Spinner-medium",
        className
      )}
      {...props}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Spinner.propTypes = propTypes;

export default Spinner;
